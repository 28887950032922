import { requestApi } from './auth.service';

// eslint-disable-next-line no-underscore-dangle
const _buildCreateVirtualWalletBody = (household, payment, cardPaymentProfile) => {
  const payByAch = payment.type === 'ACH Account';
  return {
    householdName: household.Account_Name,
    payByAch,
    ...(!payByAch && { customerProfileId: cardPaymentProfile?.customerProfileId }),
    ...(!payByAch && { paymentProfileId: cardPaymentProfile?.paymentProfileId }),
    ...(payByAch && { achName: payment?.nameOnAccount }),
    ...(payByAch && { achNickname: payment?.bankName }),
    ...(payByAch && { achRouting: payment?.routingNum }),
    ...(payByAch && { achAccount: payment?.accountNum }),
  };
};

const createVirtualWallet = async (household, payment, cardPaymentProfile) => {
  const body = _buildCreateVirtualWalletBody(household, payment, cardPaymentProfile);

  const res = await requestApi({
    method: 'post',
    url: `/finances/virtual-wallets`,
    data: body,
  });
  return res.data;
};

const getVirtualWallet = async () => {
  try {
    const { data } = await requestApi({
      method: 'get',
      url: `/finances/virtual-wallets`,
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export { getVirtualWallet, createVirtualWallet };
