import { requestApi } from './auth.service';
import { setAuthNet } from '../helpers/localStorageHelper';

/* eslint-disable camelcase */
export const createAuthNetCustomerProfile = async (addressInfo, payment) => {
  try {
    const authNetRes = await requestApi({
      method: 'post',
      url: '/finances/card-payment-profiles',
      data: { addressInfo, payment },
    });

    if (authNetRes.data?.messages.resultCode === 'Error') {
      throw authNetRes.data?.messages.message[0];
    } else {
      return authNetRes.data;
    }
  } catch (err) {
    console.log(err);
    const { messages } = err;
    if (messages) {
      if (messages.message[0].code === 'E00039') {
        const message = messages.message[0].text;
        const duplicatedId = message.match(/\d+/)[0];
        return { isDuplicate: true, duplicatedId };
      }
      console.log('createAuthNetCustomerProfile error', err);
      throw messages.message[0];
    } else {
      console.log('createAuthNetCustomerProfile error', err);
      if (err.code === 'E00003') {
        err.text = 'Error. Please check your card number and expiration date and try again.';
        throw err;
      }
      throw err;
    }
  }
};

export const getAuthNetCustomerProfile = async (Authorize_Net_Customer_Id) => {
  if (Authorize_Net_Customer_Id) {
    try {
      const authNetRes = await requestApi({
        method: 'get',
        url: `/finances/card-payment-profiles/${Authorize_Net_Customer_Id}`,
      });
      if (authNetRes.data?.messages.resultCode === 'Error') {
        throw authNetRes.data?.messages.message[0].text;
      } else {
        setAuthNet(authNetRes.data?.profile);
        return authNetRes.data?.profile;
      }
    } catch (err) {
      const { message } = err;
      if (message) {
        console.log('getCustomerProfile error with response', err);
        throw message;
      } else {
        console.log('getCustomerProfile error', err);
        throw err[0];
      }
    }
  } else {
    setAuthNet('No Account');
    return 'No Account';
  }
};

export const deleteAuthNetCustomerProfile = async (customerProfileId) => {
  console.log(customerProfileId);
  try {
    const authNetRes = await requestApi({
      method: 'delete',
      url: `/finances/card-payment-profiles/${customerProfileId}`,
    });
    console.log('authNetRes', authNetRes.data);
    return authNetRes.data;
  } catch (err) {
    const { messages } = err;
    if (messages) {
      console.log('deleteAutNetProfile error with response', err);
      if (messages.message[0].code === 'E00040') {
        return 'deleted';
      }
      throw messages.message[0];
    } else {
      console.log('deleteAutNetProfile error', err);
      throw err[0];
    }
  }
};
