import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { getAuthNet, getCurrentUser, getHousehold, getMembers, getZippy } from 'helpers/localStorageHelper';
import { fetchHousehold } from './household/slice';
import { fetchCardPaymentProfile, fetchVirtualWallet, updatePaymentMethod } from './paymentMethod/slice';
import { rootMounted } from './actions';
import { fetchMembersInHousehold } from './members/slice';
import { login } from './auth/slice';

// Best to define this in a separate file, to avoid importing
// from the store file into the rest of the codebase
export const listenerMiddleware = createListenerMiddleware();
export const { startListening, stopListening } = listenerMiddleware;

startListening({
  actionCreator: fetchHousehold.fulfilled,
  effect: (action, { dispatch }) => {
    dispatch(fetchVirtualWallet());
    dispatch(fetchCardPaymentProfile(action.payload.Authorize_Net_Customer_Id));
  },
});

startListening({
  matcher: isAnyOf(updatePaymentMethod.fulfilled, login.fulfilled),
  effect: (action, { dispatch }) => {
    dispatch(fetchHousehold());
  },
});

// load household if it is not present in local storage
startListening({
  actionCreator: rootMounted,
  effect: (action, { dispatch }) => {
    const household = getHousehold();
    const members = getMembers();
    const zippy = getZippy();
    const authNet = getAuthNet();
    if (!household || !members || !authNet || !zippy) {
      const user = getCurrentUser();
      if (user) {
        dispatch(fetchHousehold());
      }
    }
  },
});

startListening({
  actionCreator: fetchHousehold.fulfilled,
  effect: async (action, { dispatch }) => {
    dispatch(fetchMembersInHousehold());
  },
});
