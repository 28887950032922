import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getActualizedHousehold,
  getSingleHousehold,
  updateHousehold as _updateHousehold,
} from 'services/households.service';
import { getBaseMembership as _getBaseMembership, getBaseMemberships, getAddons } from 'services/memberships.service';
import { getHousehold, setBaseMembership, getBaseMembership, setHousehold } from '../../helpers/localStorageHelper';

const fetchHousehold = createAsyncThunk('household/fetch', async () => getSingleHousehold());

const updateHousehold = createAsyncThunk('household/update', async (data) => _updateHousehold(data));

const actualizeHousehold = createAsyncThunk('household/actualize', async () => getActualizedHousehold());

const fetchBaseMembership = createAsyncThunk('household/fetchBaseMembership', async (membershipId) =>
  _getBaseMembership(membershipId)
);

const fetchBaseMemberships = createAsyncThunk('household/fetchBaseMemberships', async () => getBaseMemberships());

const fetchAddOns = createAsyncThunk('household/fetchAddOns', async () => getAddons());

const initialState = {
  record: getHousehold(),
  baseMembership: getBaseMembership(),
  baseMemberships: [],
  loading: false,
  loadingBaseMembership: false,
  addOns: [],
  loadingAddOns: false,
  error: '',
};

const householdSlice = createSlice({
  name: 'household',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchHousehold.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHousehold.fulfilled, (state, action) => {
        state.record = action.payload;
        state.error = null;
        state.loading = false;
        setHousehold(action.payload);
      })
      .addCase(fetchHousehold.rejected, (state, action) => {
        state.record = null;
        state.error = action.error?.message || action.error;
        state.loading = false;
      })
      .addCase(updateHousehold.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHousehold.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        setHousehold(action.payload);
      })
      .addCase(updateHousehold.rejected, (state, action) => {
        state.error = action.error?.message || action.error;
        state.loading = false;
      })
      .addCase(actualizeHousehold.pending, (state) => {
        state.loading = true;
      })
      .addCase(actualizeHousehold.fulfilled, (state, action) => {
        state.record = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(actualizeHousehold.rejected, (state, action) => {
        state.error = action.error?.message || action.error;
        state.loading = false;
      })
      .addCase(fetchBaseMembership.pending, (state, action) => {
        state.loadingBaseMembership = true;
      })
      .addCase(fetchBaseMembership.fulfilled, (state, action) => {
        [state.baseMembership] = action.payload;
        state.error = null;
        state.loadingBaseMembership = false;
        setBaseMembership(action.payload[0]);
      })
      .addCase(fetchBaseMembership.rejected, (state, action) => {
        state.error = action.error?.message || action.error;
        state.loadingBaseMembership = false;
      })
      .addCase(fetchBaseMemberships.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchBaseMemberships.fulfilled, (state, action) => {
        state.baseMemberships = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(fetchBaseMemberships.rejected, (state, action) => {
        state.error = action.error?.message || action.error;
        state.loading = false;
      })
      .addCase(fetchAddOns.pending, (state, action) => {
        state.loadingAddOns = true;
      })
      .addCase(fetchAddOns.fulfilled, (state, action) => {
        [state.addOns] = action.payload;
        state.error = null;
        state.loadingAddOns = false;
      })
      .addCase(fetchAddOns.rejected, (state, action) => {
        state.addOns = [];
        state.error = action.error?.message || action.error;
        state.loadingAddOns = false;
      });
  },
});

export { fetchHousehold, actualizeHousehold, fetchBaseMembership, fetchAddOns, updateHousehold, fetchBaseMemberships };
export default householdSlice.reducer;
