import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { savePaymentMethod } from 'services/payment-method.service';
import { getAuthNet, getZippy, setAuthNet, setZippy } from '../../helpers/localStorageHelper';
import { getVirtualWallet } from '../../services/virtual-wallets.service';
import { getAuthNetCustomerProfile } from '../../services/authNetService';

const fetchVirtualWallet = createAsyncThunk('paymentMethod/fetchVirtualWallet', async () => {
  const virtualWallet = await getVirtualWallet();
  setZippy(virtualWallet);
  return virtualWallet;
});

const fetchCardPaymentProfile = createAsyncThunk(
  'paymentMethod/fetchCardPaymentProfile',
  async (cardPaymentProfileId) => {
    const cardPaymentProfile = await getAuthNetCustomerProfile(cardPaymentProfileId);
    setAuthNet(cardPaymentProfile);
    return cardPaymentProfile;
  }
);

const updatePaymentMethod = createAsyncThunk(
  'paymentMethod/updatePaymentMethod',
  async (payment, { getState, rejectWithValue }) => {
    const household = getState().household.record;

    try {
      const { virtualWallet, authNet } = await savePaymentMethod(payment, household);
      setZippy(virtualWallet);
      setAuthNet(authNet);
      return { virtualWallet, cardPaymentProfile: authNet };
    } catch (err) {
      console.log('Error while updating payment method');
      console.log(err);
      setZippy({});
      setAuthNet({});
      return rejectWithValue(err.message);
    }
  }
);

const initialState = {
  virtualWallet: getZippy(),
  virtualWalletError: '',
  cardPaymentProfile: getAuthNet(),
  cardPaymentProfileError: '',
  error: '',
  loading: false,
};

const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchVirtualWallet.fulfilled, (state, action) => {
        state.virtualWallet = action.payload;
      })
      .addCase(fetchVirtualWallet.rejected, (state, action) => {
        state.virtualWalletError = action.error?.message || action.error;
      })
      .addCase(fetchCardPaymentProfile.fulfilled, (state, action) => {
        state.cardPaymentProfile = action.payload;
      })
      .addCase(fetchCardPaymentProfile.rejected, (state, action) => {
        state.cardPaymentProfileError = action.error?.message || action.error;
      })
      .addCase(updatePaymentMethod.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePaymentMethod.fulfilled, (state, action) => {
        const { virtualWallet, cardPaymentProfile } = action.payload;
        state.virtualWallet = virtualWallet;
        state.cardPaymentProfile = cardPaymentProfile;
        state.loading = false;
      })
      .addCase(updatePaymentMethod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.virtualWallet = null;
        state.cardPaymentProfile = null;
      });
  },
});

export { updatePaymentMethod, fetchCardPaymentProfile, fetchVirtualWallet };
export default paymentMethodSlice.reducer;
