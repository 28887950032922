/* eslint-disable no-plusplus */
const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

const nthNumber = (number) => {
  if (number > 3 && number < 21) return 'th';
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

// Returns true if current env equals one of the arguments.
const isEnv = (...args) => args.reduce((prev, cur) => prev || cur === process.env.REACT_APP_ENVIRONMENT, false);

const getFormattedDate = (date) =>
  date
    ? new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    : '';

const getFormattedDateAndTime = (date) =>
  date
    ? new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(date))
    : '';

// Wait for specified time in ms
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const removeNullValues = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });
};

const range = (size, startAt = 0) => [...Array(size).keys()].map((i) => i + startAt);

// temporary implementation
const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

const getAge = (birthDate) => {
  if (!birthDate) return 0;
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export {
  mapOrder,
  getCurrentTime,
  nthNumber,
  isEnv,
  sleep,
  getFormattedDate,
  getFormattedDateAndTime,
  removeNullValues,
  range,
  getAge,
  deepClone,
};
