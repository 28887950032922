/* eslint-disable no-underscore-dangle */
import { requestApi } from './auth.service';

const _buildSavePaymentMethodBody = (payment, household) => {
  const nameField = payment.nameOnAccount ? payment.nameOnAccount : payment.nameOnCC;
  const fullName = nameField.split(' ');
  const [firstName, lastName = ''] = fullName;
  return {
    firstName,
    lastName,

    phone: household.Phone,
    email: household.Household_Email,
    householdNo: household.Household_No,

    ...payment,

    achName: payment?.nameOnAccount,
    achNickname: payment?.bankName,
    achRouting: payment?.routingNum,
    achAccount: payment?.accountNum,

    payByAch: !!payment?.accountNum,
  };
};
const savePaymentMethod = async (payment, household) => {
  const body = _buildSavePaymentMethodBody(payment, household);

  const res = await requestApi({
    method: 'post',
    url: `/finances/payment-methods`,
    data: body,
  });
  return res.data;
};

export { savePaymentMethod };
