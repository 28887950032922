import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMembers, setCurrentUser, setMembers } from 'helpers/localStorageHelper';
import {
  getMembersByHousehold,
  getMembersInHousehold,
  updateMember as _updateMember,
  updateMemberDuringRegistration,
} from '../../services/members.service';

const fetchMembersByHousehold = createAsyncThunk('members/fetchByHousehold', async () => getMembersByHousehold());

const fetchMembersInHousehold = createAsyncThunk('members/fetchByHousehold', async () => {
  const members = await getMembersInHousehold();
  setMembers(members);
  return members;
});

const updateMember = createAsyncThunk('members/update', async (data) => {
  await _updateMember(data);
  const members = await getMembersByHousehold();
  setMembers(members);
  setCurrentUser(members.find((member) => member.id === data.id));
  return members;
});

const updateMemberOnRegistration = createAsyncThunk('members/update', async (data) => {
  await updateMemberDuringRegistration(data);
  const members = await getMembersByHousehold();
  setMembers(members);
  setCurrentUser(members.find((member) => member.id === data.id));
  return members;
});

const initialState = {
  members: getMembers(),
  loading: false,
  error: '',
  single: null,
};

const membersSlice = createSlice({
  name: 'members',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembersByHousehold.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMembersByHousehold.fulfilled, (state, action) => {
        state.loading = false;
        state.members = action.payload;
        setMembers(action.payload);
      })
      .addCase(fetchMembersByHousehold.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || action.error;
      })
      .addCase(updateMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMember.fulfilled, (state, action) => {
        state.loading = false;
        state.members = action.payload;
      })
      .addCase(updateMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || action.error;
      });
  },
});

export { fetchMembersByHousehold, updateMember, fetchMembersInHousehold, updateMemberOnRegistration };
export default membersSlice.reducer;
